import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Question, Session } from '@/interfaces'

@Component({
  name: 'GtrSurveyQuestion'
})
export default class GtrSurveyQuestion extends Vue {
  @Prop({ default: false, required: true })
  value!: Question;

  // prop takes index of question in questions array
  @Prop({ default: 0, required: true })
  index!: number;

  @Prop({ required: true })
  sessions!: Session[]

  questionTypes: Record<string, string>[] = [
    {
      label: 'Multiple Choice',
      value: 'radio'
    }, {
      label: 'Checkboxes',
      value: 'checkbox'
    }, {
      label: 'Text',
      value: 'text'
    }
  ]

  private language = 'en'

  hasCorrectAnswer = false

  // during component load, check if the value prop has a correct answer (correct_answer). If yes, set hasCorrectAnswer to true.
  created (): void {
    if (this.value.correct_answer) {
      this.hasCorrectAnswer = true
    }
  }

  get rated (): number {
    return this.value.rate
  }

  set rated (newRate) {
    this.value.rate = +newRate
  }

  // Computed properties
  get questionNumber (): number {
    return this.index + 1
  }

  get isNotATextQuestion (): boolean {
    return this.value.type !== 'text'
  }

  get isRadioQuestion (): boolean {
    return this.value.type === 'radio'
  }

  get questionText (): string {
    return this.value.label[this.language]
  }

  set questionText (text) {
    this.value.label[this.language] = text
  }

  get questionType (): string {
    return this.value.type
  }

  set questionType (newType: string) {
    this.value.type = newType
  }

  set questionRequired (required: number) {
    this.value.required = required
  }

  get questionRequired (): number {
    return this.value.required
  }

  get minRating (): number | string {
    return this.value.range.start
  }

  set minRating (newMin: number | string) {
    if (typeof newMin === 'string') {
      this.value.range.start = parseInt(newMin)
    } else {
      this.value.range.start = newMin
    }
  }

  get maxRating (): number | string {
    return this.value.range.end
  }

  set maxRating (newMax: number | string) {
    if (typeof newMax === 'string') {
      this.value.range.end = parseInt(newMax)
    } else {
      this.value.range.end = newMax
    }
  }

  get invalidRating (): boolean {
    if (this.questionType === 'radio' && this.rated) {
      return (this.minRating === this.maxRating)
    }
    return false
  }

  get hasNoAnswers (): boolean {
    if (['radio', 'checkbox'].includes(this.questionType) && !this.rated) {
      if (this.questionAnswers.some((a: any) => !a)) {
        return true
      }
    }
    return false
  }

  get questionHasErrors (): boolean {
    return this.hasNoAnswers || this.invalidRating
  }

  get questionErrorMessage (): string {
    if (this.hasNoAnswers) {
      return 'This question doesn\'t have some answers set. Delete empty answer fields or fill them in.'
    }
    if (this.invalidRating) {
      return 'This questions rating is invalid. The min and max must be different numbers'
    }
    return ''
  }

  // TODO: The types on the parameter can probably be clean up at some point.
  set questionSession (newSession: string[] | null) {
    this.value.session_uuid = newSession
  }

  get questionSession (): string[] | null {
    return this.value.session_uuid
  }

  get correctAnswer (): string | Array<string> | null {
    return (this.value.correct_answer as string | Array<string> | null)
  }

  set correctAnswer (newAnswer: string | Array<string> | null) {
    this.value.correct_answer = newAnswer
  }

  get questionAnswers (): any[] {
    return this.value.range?.other ?? []
  }

  set questionAnswers (newAnswers: any[]) {
    this.value.range.other = newAnswers
  }

  get sessionSelectOptions () {
    return this.sessions.map(session => {
      return {
        label: session.name,
        value: session.uuid
      }
    })
  }

  get allSessionsSelected (): boolean {
    return this.sessionSelectOptions?.length === this.questionSession?.length
  }

  toggleAllSessions () {
    if (this.allSessionsSelected) {
      this.questionSession = []
    } else {
      this.questionSession = this.sessionSelectOptions.map(session => session.value)
    }
  }

  @Watch('questionType', { immediate: true })
  onQuestionTypeChange (newType: string): void {
    if (newType !== 'text') {
      // If there is no range create it.
      if (!this.value.range) {
        this.value.range = {
          start: 0,
          end: 0,
          other: ['', '']
        }
      }

      // the checkbox answers need an array to store multiple answers.
      if (newType === 'checkbox') {
        this.value.correct_answer = []
      } else {
        this.value.correct_answer = null
      }
    } else {
      delete this.value.range
    }
  }

  // Handlers
  onDeleteQuestion (): void {
    this.$emit('delete-question', this.value.id)
  }

  addAnswerToQuestion () {
    const currentAnswers = this.questionAnswers
    // since answers (other in the range attribute) is an array of strings (as far as I have seen). Pushing an empty string should work here to add a new answer.
    currentAnswers.push('')
    this.questionAnswers = currentAnswers
  }

  onDeleteAnswer (index): void {
    // take questions (which is an array of questions) and remove the question at the index passed in without mutating the original array
    const currentAnswers = this.questionAnswers
    currentAnswers.splice(index, 1)
    this.questionAnswers = currentAnswers
  }
}
